<template>
  <b-modal
    v-model="onControl"
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="TRACKING COMMISSION"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <filter-slot
      :no-visible-principal-filter="true"
      :total-rows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :start-page="FilterSlot.startPage"
      :to-page="FilterSlot.toPage"
      :filter="[]"
      :filter-principal="{}"
      @reload="$refs['trackingTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="trackingTable"
          slot="table"
          :items="myProvider"
          primary-key="id"
          responsive="sm"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          :fields="visibleFields"
          class="blue-scrollbar mt-2"
          :busy="isBusy"
          :per-page="FilterSlot.paginate.perPage"
          :filter="searchInput"
          :current-page="FilterSlot.paginate.currentPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(new_value)="data">
            {{ data.item.type_comission_number === 1 ? "$" : " " }}
            {{ data.item.new_value }}
            {{ data.item.type_comission_number === 2 ? "%" : " " }}
          </template>
          <template #cell(old_value)="data">
            {{ data.item.old_type_comission_number === 1 ? "$" : " " }}
            {{ data.item.old_value }}
            {{ data.item.old_type_comission_number === 2 ? "%" : " " }}
          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by }} <br />
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(evaluated_by)="data">
            {{ data.item.evaluated_by }} <br />
            {{ data.item.updated_at | myGlobalWithHour }}
          </template>
          <template #cell(status_description)="data">
            <b-badge
              :variant="badgeColor(data.item.status)"
              class="text-center w-100"
            >
              {{ data.item.status_description }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.status === 1"
              size="sm"
              variant="outline-danger"
              @click="cancelChange(data.item.id, data.item)"
            >
              <feather-icon icon="XIcon" class="mr-50" />
              <span>Cancel</span>
            </b-button>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import CeSetCommissionsService from "@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service";
import { mapGetters } from "vuex";
export default {
  props: {
    serviceData: {
      required: true,
    },
    commissionId: {
      type: Number,
    },
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      fields: [
        {
          key: "type_description",
          sortable: false,
          label: "Type",
          visible: true,
        },
        {
          key: "old_value",
          sortable: false,
          label: "Old Value",
          visible: true,
          class:"text-center"
        },
        {
          key: "new_value",
          sortable: false,
          label: "New Value",
          visible: true,
          class:"text-center"
        },
        {
          key: "created_by",
          sortable: true,
          label: "Requested by",
          visible: true,
          class:"text-center"
        },
        {
          key: "evaluated_by",
          sortable: false,
          label: "Processed by",
          visible: true,
          class:"text-center"
        },
        {
          key: "status_description",
          sortable: false,
          label: "Status",
          thStyle: {
            textAlign: "center",
          },
          visible: true,
          class:"text-center"
        },
        {
          key: "actions",
          sortable: false,
          label: "Actions",
          visible: true,
          class:"text-center"
        },
      ],
      items: [],
      searchInput: "",
    };
  },
  computed: {
    ...mapGetters({
      loading: "app/loading",
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async myProvider(ctx) {
      try {
        const params = {
          _page: ctx.currentPage,
          _per_page: ctx.perPage,
          p_id: this.commissionId,
        };
        const  data  = await SettingsServiceSp.getCommissionTracking(params);
        this.FilterSlot.startPage = data.from;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.toPage = data.to;
        this.items = data;
        return data.data;
      } catch (ex) {
        throw ex;
      }
    },
    badgeColor(badge) {
      let badgeClass = {
        1: "light-info", // PENDING
        2: "light-success", // APPROVED
        3: "light-danger", // DISAPPROVED
        4: "light-warning", // DELETED
      };
      return badgeClass[badge];
    },
    async cancelChange(id, item) {
      await SettingsServiceSp.cancelCommission({
        id_service: item.id_service,
        old_value: item.old_value,
        old_type_commission: item.old_type_commission
      });
      const response = await CeSetCommissionsService.updateStatusCommission({
        tracking_id: id,
        status: 4,
        user_id: this.currentUser.user_id,
        type_table: item.type_table,
      });
      if (response.status === 200) {
        item.status = 4;
        item.status_description = "CANCELLED";
        this.showSuccessSwal()
        this.$emit("refresh");
      }
    },
  },
};
</script>