var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"sm","title":_vm.titleModal(),"title-class":"h5 text-white font-weight-bolder","no-close-on-backdrop":""},on:{"hide":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","active:":"","isActive":""},on:{"click":_vm.saveSetting}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ValidationObserver',{ref:"observer"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"input-live"}},[_vm._v("NAME:")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"id":"input-live","autofocus":"","disabled":_vm.itemTypeNrc != null && _vm.itemTypeNrc.type_services != 'OTHER SERVICES' && _vm.itemTypeNrc.tab == 'services'  ? true : false},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),(_vm.isServicesTab)?_c('ValidationProvider',{attrs:{"rules":"required","name":"price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"id":"price","label":"MIN PRICE","label-for":"price"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'is-invalid' : null,model:{value:(_vm.currentPrice),callback:function ($$v) {_vm.currentPrice=$$v},expression:"currentPrice"}},'money',_vm.vMoney,false))],1)]}}],null,false,3167515634)}):_vm._e(),(_vm.isManualTab)?_c('ValidationProvider',{attrs:{"rules":"required","name":"ob_cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"id":"obtained-cost","label":"OBTAINED COST","label-for":"obtained-cost"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'is-invalid' : null,model:{value:(_vm.obtainerEx),callback:function ($$v) {_vm.obtainerEx=$$v},expression:"obtainerEx"}},'money',_vm.vMoney,false))],1)]}}],null,false,1623570385)}):_vm._e(),(_vm.isManualTab)?_c('ValidationProvider',{attrs:{"rules":"required","name":"acq_cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"id":"acquisition-cost","label":"ACQUISITION COST","label-for":"acquisition-cost"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'is-invalid' : null,model:{value:(_vm.acquisitionEx),callback:function ($$v) {_vm.acquisitionEx=$$v},expression:"acquisitionEx"}},'money',_vm.vMoney,false))],1)]}}],null,false,977494471)}):_vm._e()],1)],1),(_vm.isManualTab)?_c('div',{staticClass:"mt-1 d-flex justify-content-center align-items-center"},[_c('span',[_vm._v("Only file")]),_c('b-col',[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),(_vm.status != '')?_c('span',[_vm._v("YES")]):_c('span',[_vm._v("NO")])],1)]),_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"light-success"}},[_c('span',{staticStyle:{"font-size":"17px"}},[_vm._v(" Total: $ "+_vm._s(_vm.totalCost.toFixed(2))+" ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }