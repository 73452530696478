import { amgApi } from '@/service/axios'

class CeSetCommissionsService {
  // eslint-disable-next-line class-methods-use-this
  async getCommissionData(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/get-ced-commission', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCedCommissionRoles(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/get-ced-roles-commission', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCedCommissionTypes(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/get-ced-commission-types', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async saveChangeCommission(body) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/insert-tracking-ced-commission', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async updateStatusCommission(body) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/update-status-commission', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async searchCedSettingsCommission(body) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/search-ced-settings-commission', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCategoryCommission(body) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/get-category-commission', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCountRequestsForApprovalPending() {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.get('/credit-experts-digital/get-request-approvals-pending')
      return data
    } catch (error) {
      throw error
    }
  }

  async getCountRequestsForCreatedCommissions() {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.get('/credit-experts-digital/get-count-requests-for-created-commission')
      return data
    } catch (error) {
      throw error
    }
  }

  async getCountRequestsForSalesProcessCommissions() {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.get('/credit-experts-digital/get-count-requests-for-sales-process-commission')
      return data
    } catch (error) {
      throw error
    }
  }

  async createCedCommission(params) {
    // eslint-disable-next-line no-useless-catch
    /*
        Recibe los siguientes parametros
        description: nombre de la categoria de comision de tipo cargo a guardar
        value: el valor de la comision, por ejm: 2.5, 1.20
        typeCommission: el tipo de comision, puede ser de tipo porcentaje o monto
        moduleId: el id del modulo al cual le corresponde tal comision,
        categoryId: el id de la categoria para tal comision, tales categorias estan especificadas en ced_category_commission
    */
    try {
      const data = await amgApi.post('/credit-experts-digital/create-ced-commission', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async createTypeCharge(params) {
    // eslint-disable-next-line no-useless-catch
    /*
        Recibe los siguientes parametros
        description: nombre del nuevo charge
        moduleId: el id del modulo al cual le corresponde tal charge,
        userId: el usuario quien crea el charge
    */
    try {
      const data = await amgApi.post('/credit-experts-digital/create-type-charge', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getTypesChargesCommission(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      // const data = await amgApi.get('/credit-experts-digital/get-types-charges-commission')
      const data = await amgApi.post('/credit-experts-digital/get-types-charges-commission',params)
      return data
    } catch (error) {
      throw error
    }
  }

  async listDynamicCommissionsCed(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/list-dynamic-commissions-ced', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async searchSalesProcessCommissions(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/search-sales-process-commissions', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async approveDisapproveDynamicCommission(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/approve-disapprove-dynamic-commission', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async updateMinAmountCommission(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/credit-experts-digital/update-min-amount-commission', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getGeneralCommissionTrackingById(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/crm/manage-commissions/get-general-commission-tracking-by-id', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCommissionById(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/crm/manage-commissions/get-commission-by-id', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async approveDisapproveSalesCommission(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/crm/manage-commissions/approve-disapprove-sales-commission', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getEditedColumnsCommissions(params) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post('/crm/manage-commissions/get-edited-columns-commissions', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCommissionIdBySlug(slug) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.get(`/credit-experts-digital/get-commission-id-by-slug/${slug}`)
      return data
    } catch (error) {
      throw error
    }
  }
}
export default new CeSetCommissionsService()
