<template>
  <b-modal v-model="showModal" size="sm" :title="titleModal()" title-class="h5 text-white font-weight-bolder" @hide="closeModal" no-close-on-backdrop>
    <ValidationObserver ref="observer">
      <b-row>
        <b-col cols="12">
          <label for="input-live">NAME:</label>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <b-form-input
              id="input-live"
              v-model="name"
              autofocus
              :class="errors[0] == 'is required' && 'border-danger rounded'"  
              :disabled="itemTypeNrc != null && itemTypeNrc.type_services != 'OTHER SERVICES' && itemTypeNrc.tab == 'services'  ? true : false"            
            />
          </ValidationProvider>

          <ValidationProvider
            v-if="isServicesTab"
            v-slot="{ errors }"
            rules="required"
            name="price"
          >
            <b-form-group
              class="mt-1"
              id="price"
              label="MIN PRICE"
              label-for="price"
            >
              <money
                v-bind="vMoney"
                v-model="currentPrice"
                class="form-control"
                :class="errors[0] ? 'is-invalid' : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            v-if="isManualTab"
            v-slot="{ errors }"
            rules="required"
            name="ob_cost"
          >
            <b-form-group
              class="mt-1"
              id="obtained-cost"
              label="OBTAINED COST"
              label-for="obtained-cost"
            >
              <money
                v-bind="vMoney"
                v-model="obtainerEx"
                class="form-control"
                :class="errors[0] ? 'is-invalid' : null"
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            v-if="isManualTab"
            v-slot="{ errors }"
            rules="required"
            name="acq_cost"
          >
            <b-form-group
              class="mt-1"
              id="acquisition-cost"
              label="ACQUISITION COST"
              label-for="acquisition-cost"
            >
              <money
                v-bind="vMoney"
                v-model="acquisitionEx"
                class="form-control"
                :class="errors[0] ? 'is-invalid' : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>

      <div class="mt-1 d-flex justify-content-center align-items-center" v-if="isManualTab">
        <span>Only file</span>
        <b-col >
          <div class="d-flex">
            <b-form-checkbox v-model="status" switch />
            <span v-if="status != ''">YES</span>
            <span v-else>NO</span>
          </div>
        </b-col>

        <b-badge variant="light-success" class="ml-2">
          <span style="font-size: 17px">
           Total: $ {{ totalCost.toFixed(2) }}
          </span>
        </b-badge>
        </div>


    </ValidationObserver>
    <template #modal-footer>
      <b-button variant="primary" active: isActive @click="saveSetting">
        Save
      </b-button>
      <b-button variant="secondary" @click="closeModal">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ncrTypeService from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import VMoney from "v-money";

export default {
  component: {
    VMoney,
  },

  props: {
    mode: String,
    itemTypeNrc: {
      type: Object,
      default: () => null,
    },
    tabSelected: String,
    subTabSelected: {
      type: String,
      default: null,
      required: false,
    },
    menuItem: String,
  },

  data() {
    return {
      status: true,
      item: "",
      obtainerEx: 0,
      acquisitionEx: 0,
      currentPrice: 0,
      isActive: true,
      id: null,
      typePriceNcr: 1,
      levels: [],
      levelId: null,
      level: null,
      levelSelected: null,
      typesNrc: [
        { text: "Automatic", value: 1 },
        { text: "Manual", value: 2 },
      ],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
        maxlength: 11,
      },
      name: null,
      initialName: null,
      initialStatus: null,
      typeNrc: 1,
      created_by: null,
      expenses: 0,
      idService: null,
      itemActive: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    totalCost() {
      return (this.expenses =
        parseFloat(this.obtainerEx) + parseFloat(this.acquisitionEx));
    },

    isEditMode() {
      return this.mode == "Update";
    },

    isManualTab() {
      return this.tabSelected === "manual";
    },
    isQuestionnaireTab() {
      return this.tabSelected === "questionnaire";
    },
    isServicesTab() {
      return this.tabSelected === "services";
    },
  },
  watch: {
    itemTypeNrc: {
      handler(value) {
        if (value && value.id) {
          this.id = this.itemTypeNrc.id;
          this.name =
            this.tabSelected == "services"
              ? this.itemTypeNrc.description
              : this.itemTypeNrc.name;
          this.status = !(
            this.itemTypeNrc.only_file === 0 ||
            this.itemTypeNrc.only_file === null
          );
          this.created_by = this.itemTypeNrc.created_by;
          this.expenses = value.expenses;
          this.obtainerEx = value.obtainer_cost;
          this.acquisitionEx = value.acquisition_cost;
          this.currentPrice = this.itemTypeNrc.minimum_price;
          this.idService = this.itemTypeNrc.id;
          this.itemActive = this.itemTypeNrc.is_active;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.openModal();
  },

  methods: {
    async saveSetting() {
      let isValid = await this.$refs["observer"].validate();
      if (isValid) {
        const confirm = await this.showConfirmSwal("Are you sure?", null);
        if (!confirm.value) {
          return;
        }
        this.addPreloader();
        const formdata = {
          id: this.id,
          name: this.name,
          userId: this.currentUser.user_id,
          onlyFile: this.status === false ? 0 : 1,
          expenses: this.expenses,
          obtainer_cost: this.obtainerEx,
          acquisition_cost: this.acquisitionEx,
          price: this.currentPrice,
          category: Number(this.subTabSelected),
          isActive: this.itemActive,
        };
        this.isActive = false;

        const methodMapping = {
          manual: this.isEditMode
            ? "updateManualNcrType"
            : "saveManualNcrTypes",
          questionnaire: this.isEditMode
            ? "updateQuestionnaireItem"
            : "saveQuestionnaireItem",
          settings: this.isEditMode
            ? "updateAppointmentCancellationMotive"
            : "saveAppCancelMotive",
          services: this.isEditMode ? "updateService" : "saveService",
        };

        const methodService = methodMapping[this.tabSelected];
        let { data } = await ncrTypeService[methodService](formdata);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }
        this.$emit("refresh");
        this.$emit("close");
        this.removePreloader();
      }
    },

    titleModal() {
      const tabSelectedMapping = {
        manual: this.isEditMode ? "UPDATE MANUAL NCR" : "CREATE MANUAL NCR",
        questionnaire: this.isEditMode
          ? "UPDATE QUESTIONNAIRE OPTION"
          : `ADD ${this.menuItem}`,
        settings: this.isEditMode
          ? "UPDATE CANCELLATION MOTIVE"
          : "ADD CANCELLATION MOTIVE",
        services: this.isEditMode
          ? "UPDATE OTHER SERVICE"
          : "ADD OTHER SERVICE",
      };
      return tabSelectedMapping[this.tabSelected];
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
