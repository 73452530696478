<template>
  <b-row align-h="center">
    <template v-for="(item, index) in commissions">
      <b-col :key="index" :sm="commissions.length == 1 ? '6' : '4'">
        <b-input-group>
          <b-input-group-prepend>
            <b-form-select
              v-model="item.type"
              :options="options"
              :disabled="item.edit"
            ></b-form-select>
          </b-input-group-prepend>
          <money
            v-bind="vMoney"
            class="form-control input-form"
            :class="{ 'border-danger': isInvalidCommission(item) }"
            v-model="item.valueCommission"
            :disabled="item.edit"
          />
          <b-input-group-append
            v-if="
              item.edit && (item.status == null || item.status != 'PENDING')
            "
            class="border-right"
            @click="changeEdit(item)"
          >
            <b-button variant="outline-warning" class="btn-icon">
              <feather-icon icon="Edit2Icon" class="cursor-pointer" />
            </b-button>
          </b-input-group-append>

          <b-input-group-append
            v-if="!item.edit"
            class="border-right"
            @click="
              isStorable(item) && storeCommission(item)
            "
          >
            <b-button
              variant="outline-primary"
              class="btn-icon"
              :disabled="
                !isStorable(item)
              "
            >
              <feather-icon
                icon="SaveIcon"
                :class="{
                  'cursor-pointer': isStorable(item),
                }"
              />
            </b-button>
          </b-input-group-append>

          <b-input-group-append v-if="item.status == 'PENDING'">
            <b-button
              v-b-tooltip.hover.top="'Request change is pending'"
              variant="outline-danger"
              class="btn-icon"
            >
              <feather-icon icon="InfoIcon" class="cursor-pointer" />
            </b-button>
          </b-input-group-append>

          <b-input-group-append
            v-if="!item.edit"
            @click="cancelEditCommission(item)"
          >
            <b-button variant="outline-danger" class="btn-icon">
              <feather-icon icon="XIcon" class="cursor-pointer" />
            </b-button>
          </b-input-group-append>

          <b-input-group-append
            class="cursor-pointer"
            @click="openTrackingModal(item.id)"
          >
            <b-input-group-text>
              <feather-icon icon="ListIcon" class="text-primary" />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </template>
    <TrackingCommission
      v-if="showTrackingModal"
      :serviceData="userCommission"
      :commissionId="commissionId"
      @closeModal="showTrackingModal = false"
      @refresh="updateGrid()"
    />
  </b-row>
</template>

<script>
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import TrackingCommission from "@/views/specialists/sub-modules/financial-approval/views/settings/views/commissions/modals/TrackingCommission.vue";

import { mapGetters } from "vuex";

export default {
  props: {
    userCommission: {
      type: Array,
    },
  },
  components: {
    TrackingCommission,
  },
  data() {
    return {
      selectedType: "AMOUNT",
      options: [
        { value: "AMOUNT", text: "$" },
        { value: "PERCENTAGE", text: "%" },
      ],
      valueCommission: 0,
      vMoney: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        min: 0.0,
        maxlength: 10,
      },
      commissions: [],
      showTrackingModal: false,
      commissionId: null,
      previousValues: {},
      previousType: {},
    };
  },
  methods: {
    async storeCommission(item) {
      const previousValue = this.previousValues[item.id];
      const previousType = this.previousType[item.id];

      const params = {
        description: item.userDescription,
        value: item.valueCommission,
        old_value: previousValue,
        old_type_commission: previousType,
        type_commission: item.type,
        module_id: 11,
        role_id: 22,
        ced_category_commission_id: 2,
        created_by: this.currentUser.user_id,
        id: item.id,
      };
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          await SettingsServiceSp.storeCommission(params);
          this.showSuccessSwal();
          this.$emit("refresh");
        }
      } catch (error) {
        if (error.response.status == 422) {
          var errors = error.response.data.errors;
          var errorMessage = "";
          for (var key in errors) {
            errorMessage += errors[key][0] + "\n";
          }
          this.showWarningSwal(errorMessage);
        } else {
          this.showErrorSwal(error);
        }
      }
    },
    async changeEdit(item) {
      this.$set(item, "edit", !item.edit);
    },
    openTrackingModal(id) {
      this.showTrackingModal = true;
      this.commissionId = id;
    },
    updateGrid() {
      this.$emit("refresh");
    },
    cancelEditCommission(item) {
      const previousValue = this.previousValues[item.id];
      const previousType = this.previousType[item.id];
      item.valueCommission = previousValue;
      item.type = previousType;
      item.edit = true;
    },
    isInvalidCommission(item) {
      return (
        (item.type == "PERCENTAGE" && item.valueCommission > 100) ||
        item.valueCommission < 0
      );
    },
    isSameCommission(item) {
      return (
        item.type == this.previousType[item.id] &&
        item.valueCommission == this.previousValues[item.id]
      );
    },
    isStorable(item) {
      return !(
        this.isInvalidCommission(item) ||
        this.isSameCommission(item) || 
        item.valueCommission == 0
      );
    },
  },
  computed: {
    ...mapGetters({
      loading: "app/loading",
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.commissions = this.userCommission;
    this.commissions.forEach((item) => {
      this.$set(this.previousValues, item.id, item.valueCommission);
    });
    this.commissions.forEach((item) => {
      this.$set(this.previousType, item.id, item.type);
    });
  },
};
</script>