<template>
  <div>
    <div class="commission-main">
      <div class="commission-sidebar">
        <div class="menu-sidebar" :class="{ dark: isDarkSkin }">
          <div>
            <p class="title-sidebar">SERVICES</p>
            <ul class="items-sidebar" :class="{ dark: isDarkSkin }">
              <li
                v-for="tab in menuServices"
                :key="tab.id"
                @click="changeTab(tab)"
                :class="{ active: tab.id == currentTab }"
              >
                {{ tab.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <main class="body-main">
        <filter-slot
          :filter="[]"
          :total-rows="FilterSlot.totalRows"
          :paginate="FilterSlot.paginate"
          :start-page="FilterSlot.startPage"
          :to-page="FilterSlot.toPage"
          :filter-principal="FilterSlot.filterPrincipal"
          @reload="refreshTable"
        >
          <template #buttons>
            <b-button
              variant="primary"
              class="ml-2"
              v-if="currentTab == 3"
              @click="openCrudModal(1)"
            >
              <tabler-icon icon="PlusIcon" />
              Add
            </b-button>
            <feather-icon
              icon="HelpCircleIcon"
              class="ml-2 text-info"
              v-b-tooltip.hover
              title="* Remember that management has to approve the change before it is made"
              size="22"
            />
          </template>
          <b-table
            ref="servicesTable"
            slot="table"
            class="blue-scrollbar"
            :items="myProvider"
            :fields="fields"
            primary-key="id"
            responsive="md"
            sticky-header="50vh"
            no-provider-filtering
            :per-page="FilterSlot.paginate.perPage"
            :current-page="FilterSlot.paginate.currentPage"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #top-row>
              <b-th colspan="3"></b-th>
              <b-th v-if="currentTab === 4" class="py-0">
                <b-row align-h="center">
                  <b-col
                    cols="6"
                    class="text-center p-0"
                    :class="
                      isDarkSkin ? 'bg-gradient-dark' : 'bg-gradient-secondary'
                    "
                  >
                    FOR EXECUTE
                  </b-col>
                </b-row>
              </b-th>
              <b-th v-else colspan="1" class="py-0">
                <b-row align-h="center">
                  <b-col
                    cols="4"
                    class="text-center p-0"
                    :class="
                      isDarkSkin ? 'bg-gradient-dark' : 'bg-gradient-secondary'
                    "
                    v-for="sub in subTabCommission"
                    :key="sub.id"
                  >
                    {{ sub.name }}
                  </b-col>
                </b-row>
              </b-th>
            </template>

            <template #cell(description)="{ item }">
              <template v-if="currentTab == 3">
                <div class="d-flex justify-content-start align-items-center">
                  <b-input-group v-if="!item.editName" style="width: 100%">
                    <b-input v-model="item.description" />
                  </b-input-group>
                  <span v-else class="text-truncate">{{
                    item.description
                  }}</span>
                  <div class="d-flex justify-content-center">
                    <feather-icon
                      class="cursor-pointer ml-1"
                      @click="saveChangeName(item, item.editName)"
                      :icon="item.editName ? 'Edit2Icon' : 'SaveIcon'"
                      :class="item.editName ? 'text-warning' : 'text-primary'"
                      size="16"
                    />
                    <feather-icon
                      v-if="!item.editName"
                      class="cursor-pointer text-danger"
                      @click="cancelChange(item, 'name')"
                      icon="XIcon"
                      size="16"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <span class="text-truncate">{{ item.description }}</span>
              </template>
            </template>

            <template #cell(minimum_price)="{ item }">
              <div class="d-flex justify-content-start align-items-center">
                <b-input-group v-if="!item.editPrice" style="width: 100%">
                  <money
                    v-bind="vMoney"
                    v-model="item.minimum_price"
                    class="form-control"
                  />
                </b-input-group>
                <span v-else class="text-truncate">{{
                  item.minimum_price | addTwoDecimals
                }}</span>
                <div class="d-flex justify-content-center">
                  <feather-icon
                    class="cursor-pointer ml-1"
                    @click="saveChangePrice(item, item.editPrice)"
                    :icon="item.editPrice ? 'Edit2Icon' : 'SaveIcon'"
                    :class="item.editPrice ? 'text-warning' : 'text-primary'"
                    size="16"
                  />
                  <feather-icon
                    v-if="!item.editPrice"
                    class="cursor-pointer text-danger"
                    @click="cancelChange(item, 'price')"
                    icon="XIcon"
                    size="16"
                  />
                </div>
              </div>
            </template>

            <template #cell(active)="{ item }">
              <b-badge
                :variant="item.active ? 'light-success' : 'light-warning'"
                @click="changeStatus(item)"
                class="clickable"
              >
                {{ item.active ? "ACTIVE" : "INACTIVE" }}
                <feather-icon
                  icon="RepeatIcon"
                  :class="item.active ? 'text-success' : 'text-warning'"
                  size="22"
                  v-b-tooltip.hover.top="item.active ? 'Disable' : 'Enable'"
                />
              </b-badge>
            </template>
            <template #cell(commission)="{ value }">
              <CommissionEdit
                :userCommission="JSON.parse(value)"
                @refresh="$refs.servicesTable.refresh()"
              />
            </template>
          </b-table>
        </filter-slot>
      </main>
    </div>

    <CreateModal
      v-if="showCreateModal"
      :mode="operation"
      :tabSelected="tabSelected"
      :itemTypeNrc="rowItem"
      @close="showCreateModal = false"
      @refresh="refreshTable"
    />
  </div>
</template>

<script>
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import CreateModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/CreateModal.vue";
import CommissionEdit from "@/views/specialists/sub-modules/financial-approval/views/settings/views/commissions/utils/CommisssionEdit.vue";
import ncrTypeService from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";

import { mapGetters } from "vuex";

export default {
  components: {
    CreateModal,
    CommissionEdit,
  },
  data() {
    return {
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      menuServices: [
        { id: 1, name: "CREDIT CARDS" },
        { id: 2, name: "APPOINTMENTS" },
        { id: 3, name: "OTHER SERVICES" },
        { id: 4, name: "POTENTIAL" },
      ],
      fields: [
        {
          key: "description",
          label: "Name",
          thClass: "text-left",
          tdClass: "text-left px-1 py-0",
          thStyle: "width: 20%",
        },
        {
          key: "minimum_price",
          label: "Min price",
          thClass: "text-left",
          tdClass: "text-left px-1 py-0",
          thStyle: "width: 17%",
        },
        {
          key: "active",
          label: "Status",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 3%",
          class: "px-1",
        },
        {
          key: "commission",
          label: "Commissions",
          thClass: "text-center",
          tdClass: "text-center px-1",
          thStyle: "width: 60%",
        },
      ],
      subTabCommission: [
        { id: 1, name: "FOR RECOMMEND" },
        { id: 2, name: "FOR PROCESS" },
        { id: 3, name: "FOR EXECUTE" },
      ],
      currentTab: null,
      operation: null,
      tabSelected: null,
      showCreateModal: false,
      showTrackingModal: false,
      rowItem: {},
      items: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        prefix: "$",
        min: 0.0,
        maxlength: 10,
      },
      previousName: {},
      previousPrice: {},
    };
  },
  async created() {
    this.currentTab = 1;
  },
  computed: {
    ...mapGetters({
      theme: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async myProvider() {
      const formdata = {
        page: this.FilterSlot.paginate.currentPage,
        perPage: this.FilterSlot.paginate.perPage,
        search: this.FilterSlot.filterPrincipal.model,
        type: this.currentTab,
      };

      const data = await SettingsServiceSp.getTypesChargesFinancial(formdata);
      this.FilterSlot.startPage = data.from;
      this.FilterSlot.paginate.currentPage = data.current_page;
      this.FilterSlot.paginate.perPage = data.per_page;
      this.FilterSlot.totalRows = data.total;
      this.FilterSlot.toPage = data.to;
      this.items = data.data;
      this.items.forEach((item) => {
        this.$set(item, "editName", true);
        this.$set(item, "editPrice", true);
      });
      this.items.forEach((item) => {
        this.$set(this.previousName, item.id, item.description);
        this.$set(this.previousPrice, item.id, item.minimum_price);
      });
      return this.items;
    },
    changeTab(tab) {
      this.currentTab = tab.id;
      this.FilterSlot.filterPrincipal.model = "";
      this.refreshTable();
    },
    openCrudModal(type, item = null) {
      this.operation = type == 1 ? "Create" : "Update";
      this.tabSelected = "services";
      this.rowItem = item;
      this.showCreateModal = true;
    },
    async changeStatus(data) {
      const confirm = await this.showConfirmSwal(
        `Are you sure to change the status?`,
        null
      );

      if (confirm.isConfirmed) {
        let newStatus = !data.active;
        const formdata = {
          id: data.id,
          status: newStatus,
          userId: this.currentUser.user_id,
        };
        try {
          const { data } = await SettingsServiceSp.updateService(formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
            this.refreshTable();
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    async refreshTable() {
      this.$refs["servicesTable"].refresh();
    },
    cancelChange(item, column) {
      if (column == "name") {
        const previousName = this.previousName[item.id];
        item.description = previousName;
        item.editName = true;
      } else if (column == "price") {
        const previousPrice = this.previousPrice[item.id];
        item.minimum_price = previousPrice;
        item.editPrice = true;
      }
    },
    async saveChangeName(item, type) {
      try {
        // Edit pencil
        if (type) {
          this.$set(item, "editName", !item.editName);
        }
        // Store
        else {
          const confirm = await this.showConfirmSwal("Are you sure?", null);
          if (!confirm.value) {
            return;
          }
          this.addPreloader();
          const formdata = {
            id: item.id,
            name: item.description,
            price: item.minimum_price,
          };
          await ncrTypeService.updateService(formdata);
          item.editName = true;
          // this.$refs.servicesTable.refresh();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Failed!",
          "XIcon",
          "Failed operation"
        );
      } finally {
        this.removePreloader();
      }
    },

    async saveChangePrice(item, type) {
      try {
        // Edit pencil
        if (type) {
          this.$set(item, "editPrice", !item.editPrice);
        }
        // Store
        else {
          const confirm = await this.showConfirmSwal("Are you sure?", null);
          if (!confirm.value) {
            return;
          }
          this.addPreloader();
          const formdata = {
            id: item.id,
            name: item.description,
            price: item.minimum_price,
          };
          await ncrTypeService.updateService(formdata);
          item.editPrice = true;
          // this.$refs.servicesTable.refresh();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Failed!",
          "XIcon",
          "Failed operation"
        );
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.commission-main {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  .commission-sidebar {
    width: 15%;
    .menu-sidebar {
      position: sticky;
      top: 5rem;
      margin-top: 1rem;
      padding: 1.5rem 1rem;
      background-color: #f5f5f5;
      border-radius: 1rem;
      &.dark {
        background-color: #1e1e1e;
      }
    }
  }
  .body-main {
    width: 85%;
    padding: 0rem 1.5rem;
  }
}

.title-sidebar {
  font-size: calc(1.1rem * var(--scale));
  font-weight: bold;
  margin: 0;
  padding: 1rem;
}

.items-sidebar {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    font-size: calc(1rem * var(--scale));
    padding: 0.8rem;
    cursor: pointer;
    &:hover {
      background-color: #5cacf8;
      color: #fff;
      border-radius: 0.5rem;
    }
    &.active {
      background-color: #0077e6;
      color: #fff;
      border-radius: 0.5rem;
    }
  }
  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>